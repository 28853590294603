<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import { RadioGroup, RadioButton } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import api from '@/command/api'
import { getAction, postAction } from '@/command/netTool'
import Naves from '@/views/com/Naves'
export default {
  name: 'entityInformationStoreSettings',
  data() {
    return {
      activeKey: 0,
      ...api.command.getState(),
      detail: { list: [] },
    }
  },
  mounted() {
    this.initData('0')
  },
  methods: {
    initData(type) {
      getAction(`/api/farmMainBodyConfig/list?type=${type}`).then((res) => {
        if (res.code === 200) {
          this.detail.list = res.data
        } else {
          this.$message.error(res.err || '服务器开小差，稍后再试')
        }
      })
    },
    getForm1() {
      return {
        title: '基本设置',
        type: 'cardForm',
        cols: 17,
        data: [
          ...this.getSwitchConfig(),
          {
            label: '保存',
            type: 'button',
            cols: 3,
            props: {
              style: {
                marginTop: '20px',
                marginLeft: '10px',
              },
              type: 'primary',
            },
            onClick: () => {
              this.detail.list.map((e, i) => {
                e.status = this.detail[`status${i}`]
              })
              postAction('/farmMainBodyConfig/update', this.detail.list).then((res) => {
                if (res.code === 200) {
                  this.$message.success('操作成功！')
                } else {
                  this.$message.error(res.err || '服务器开小差，稍后再试')
                }
              })
            },
          },
        ],
      }
    },
    getSwitchConfig() {
      return this.detail.list.map((item, i) => {
        this.detail[`status${i}`] = item.status
        return {
          name: item.moduleName,
          type: 'switch',
          labelCol: { span: 2 },
          wrapperCol: { span: 22 },
          cols: 24,
          key: `status${i}`,
        }
      })
    },
    getTapInputConfig() {
      return this.detail.list.map((item, i) => {
        this.detail[`showName${i}`] = item.showName
        return {
          type: 'row',
          cols: 24,
          children: [
            {
              type: 'text',
              cols: 2,
              value: i == 0 ? 'Tab栏名称' : undefined,
              style: {
                lineHeight: '36px',
              },
            },
            {
              type: 'input',
              cols: 22,
              key: `showName${i}`,
              maxLength: 4,
              placeholder: '请输入',
              showCount: true,
            },
          ],
        }
      })
    },
    getForm2() {
      return {
        title: '基本设置',
        type: 'cardForm',
        cols: 17,
        data: [
          ...this.getTapInputConfig(),
          {
            label: '保存',
            type: 'button',
            cols: 3,
            props: {
              style: {
                marginTop: '20px',
                marginLeft: '10px',
              },
              type: 'primary',
            },
            onClick: () => {
              this.detail.list.map((e, i) => {
                e.showName = this.detail[`showName${i}`]
              })
              postAction('/farmMainBodyConfig/update', this.detail.list).then((res) => {
                if (res.code === 200) {
                  this.$message.success('操作成功！')
                } else {
                  this.$message.error(res.err || '服务器开小差，稍后再试')
                }
              })
            },
          },
        ],
      }
    },
    getAppModel(data) {
      return {
        type: 'cardForm',
        cols: 7,
        data: [
          {
            type: 'iframe',
            url: `${apiTool.getSzncH5Url()}/shop?${this.activeKey == 1 ? '&tabActive=1' : ''}&imitate=true`,
            styles: {
              marginLeft: '16px',
            },
            key: this.activeKey + '',
            data,
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/commodityManagement/entityInformation'),
        },
      ]
      return {
        left,
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        let data = {}
        if (this.detail.list[0]) {
          data = {
            advertisingSpace: this.detail.list[0].status,
            shopHot: this.detail.list[1].status,
          }
        }

        return [
          {
            type: 'cardFormBox',
            children: [this.getAppModel(data), this.getForm1()],
          },
        ]
      } else if (this.activeKey === 1) {
        return [
          { type: 'cardFormBox', children: [this.getAppModel({ configList: this.detail.list }), this.getForm2()] },
        ]
      }
    },
  },
  render() {
    return (
      <div>
        <a-radio-group
          v-model={this.activeKey}
          buttonStyle="solid"
          onChange={(data) => {
            this.initData(data.target.value)
          }}
        >
          <a-radio-button value={0}>首页</a-radio-button>
          <a-radio-button value={1}>全部商品</a-radio-button>
        </a-radio-group>
        <DetailFormGroup foot={this.getFoot()} form={this.detail} data={this.renderTab()} />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
}
/deep/ .ant-radio-button-wrapper {
  height: 38px;
  padding: 0 20px;
  line-height: 36px;
}
</style>
